<template>
	<div class="agent-wrapper d-inline-flex align-items-center justify-content-center px-2 pb-1 rounded-pill">
		<div class="p-1">
			<project-icon :project="project"></project-icon>
		</div>
		<div class="text-small p-1">
			<div class="ml-2" v-text="project.Name"></div>
			<div class="ml-2 text-small" v-text="project.Code"></div>
		</div>
	</div>
</template>

<script>
	import ProjectIcon from "./project-icon";

	export default {
		components: {ProjectIcon},
		props: {
			project: {
				type: Object,
				default: () => {
					return {}
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	@import "@/assets/style/_palette";

	.agent-wrapper {
		background-color: #EFEFEF;

		&:hover {
			background-color: $color-primary;
			color: white;
		}
	}
</style>
