<template>
	<!--suppress JSSuspiciousNameCombination -->
	<div :class="`project-${parseInt(project.Code)}`"
		 :style="{width, height: width}"
		 class="icon rounded-circle d-flex align-items-center justify-content-center text-white">
		<div>{{parseInt(project.Code) | numeral('000')}}</div>
	</div>
</template>

<script>
	export default {
		props: {
			width: {
				type: String,
				default: '32px'
			},
			project: {
				type: Object,
				default: () => {
					return {}
				}
			}
		}
	}
</script>
