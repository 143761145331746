<template>
	<div class="container">
		<linked-draggable :disabled="disabled" @add="addTeamMember" @remove="removeTeamMember" :selected="members"
						  :options="agents">
			<template v-slot="{item}">
				<agent-card class="cursor-grabbable" :agent="item"/>
			</template>
		</linked-draggable>
	</div>
</template>

<script>
	import api from '@/api'
	import AgentCard from '@/components/agents/agent-card'
	import LinkedDraggable from "../ui/linked-draggable";

	export default {
		props: {
			uuid: {
				type: String,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				members: [],
				agents: []
			}
		},
		async mounted() {
			this.loading = true
			this.members = await api.Teams.getTeamMembers(this.uuid).finally(() => this.loading = false)
			this.agents = await api.Agents.getList().finally(() => this.loading = false)
		},
		methods: {
			async addTeamMember(agent) {
				this.members = await api.Teams.addTeamMembers(this.uuid, agent.Id)
			},
			async removeTeamMember(agent) {
				this.members = await api.Teams.removeTeamMembers(this.uuid, agent.Id)
			}
		},
		components: {
			LinkedDraggable,
			AgentCard
		}
	}
</script>
