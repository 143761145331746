<template>
	<div class="container">
		<linked-draggable :disabled="disabled" @add="addTeamProject" @remove="removeTeamProject" :selected="teamProjects"
						  :options="projects">
			<template v-slot="{item}">
				<project-card class="cursor-grabbable" :project="item"></project-card>
			</template>
		</linked-draggable>
	</div>
</template>

<script>
	import api from '@/api'
	import AgentCard from '@/components/agents/agent-card'
	import LinkedDraggable from "../ui/linked-draggable";
	import ProjectCard from "../projects/project-card";

	export default {
		props: {
			uuid: {
				type: String,
				default: null
			},
			disabled: {
				type: Boolean,
				default: false
			}
		},
		data() {
			return {
				loading: false,
				teamProjects: [],
				projects: []
			}
		},
		async mounted() {
			this.loading = true
			this.teamProjects = await api.Teams.getTeamProjects(this.uuid).finally(() => this.loading = false)
			this.projects = await api.Projects.getProjects(true).finally(() => this.loading = false)
		},
		methods: {
			async addTeamProject(project) {
				this.teamProjects = await api.Teams.addTeamProject(this.uuid, project.Id)
			},
			async removeTeamProject(project) {
				this.teamProjects = await api.Teams.removeTeamProject(this.uuid, project.Id)
			}
		},
		components: {
			ProjectCard,
			LinkedDraggable,
			AgentCard
		}
	}
</script>
