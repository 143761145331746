<template>
	<div class="container">
		<nav aria-label="breadcrumb">
			<ol class="breadcrumb">
				<li class="breadcrumb-item active">
					<router-link :to="{name: 'teams-index'}">Teams</router-link>
				</li>
				<li class="breadcrumb-item active" v-if="team">
					<router-link :to="{name: 'teams-edit-uuid', params: {uuid: $route.params.uuid}}"
								 v-text="team.Name"></router-link>
				</li>
			</ol>
		</nav>

		<h3>Members</h3>
		<team-members-editor :disabled="!canWrite('teams')" :uuid="$route.params.uuid"></team-members-editor>

		<div class="my-4"></div>

		<h3>Projects</h3>
		<team-projects-editor :disabled="!canWrite('teams')" :uuid="$route.params.uuid"></team-projects-editor>
	</div>
</template>

<script>
	import TeamMembersEditor from "../../../components/agents/team-members-editor";
	import TeamProjectsEditor from "../../../components/agents/team-projects-editor";
	import api from '@/api'

	export default {
		components: {
			TeamProjectsEditor,
			TeamMembersEditor
		},
		data() {
			return {
				loading: false,
				team: null,
			}
		},
		async mounted() {
			this.loading = true
			this.team = await api.Teams.load(this.$route.params.uuid).finally(() => this.loading = false)
		}
	}
</script>
